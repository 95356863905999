const textsFields: [string, string | string[]][] = [
    ["O que é o Cazzapi?", "O Cazappi é uma empresa brasileira de tecnologia que oferece soluções de comércio de alimentos para que pessoas e empresas possam comprar, vender, pagar, anunciar e entregar produtos por meio da internet."],
    ["Como é a venda pelo Cazappi?", ["1. Os clientes escolhem os produtos na sua loja no Cazappi;", "2. Te notificamos quando entra um novo pedido;", "3. Você prepara ou separa o produto para ser entregue;", "4. Toda semana você recebe o dinheiro de seus pedidos"]],
    ["Como é o processo de se cadastrar no Cazappi?", ["1. Rápido e fácil: você pode se inscrever em minutos. Preencha os seus dados, e cadastre seu negócio no cazappi;", "2. Analisaremos sua inscrição e verificaremos as informações que você nos enviou;", "3. Assim que sua inscrição for aprovada, você receberá um link para configurar sua loja, filiais, produtos, personalizar sua marca e ter acesso a ferramentas de gestão;", "4. Solicite a publicação de sua loja. Se atender às configurações corretas, você será notificado de que já está visível no aplicativo. Os clientes agora podem começar a pedir seus produtos."]],
    ["Quais requisitos para loja ser publicada no Cazappi?", ["1. Tenha sua própria loja onde você pode receber compradores, pontos de retirada, pontos de venda em outra loja e armazéns com retirada;", "2. Comprometa-se a manter o catálogo da loja atualizado com fotos e preços, bem como a disponibilidade dos produtos para que o found-rate semanal (percentual de produtos encontrados em relação aos pedidos) se mantenha acima de 85%;", "3. Ofereça os produtos que você vende na Cazappi pelo mesmo preço da sua loja;", "4. Ter uma conta bancária em nome da empresa ou representante legal da empresa;", "5. Estar disposto a entregar um serviço de qualidade ao cliente;", "6. É obrigatório que a loja emita Nota Fiscal e que a entregue para o Shopper no momento da compra."]],
    ["Por que eu deveria estar no cazappi?", ["1. Você dará aos seus clientes um atendimento único e personalizado na hora que eles precisarem;", "2. Daremos a você as ferramentas para gerenciar seus produtos e filiais e ajudá-lo a vender mais;", "3. Você descobrirá quais são os produtos mais solicitados e os que os clientes não estão encontrando."]],
    ["Quanto custa para publicar minha loja e quanto tempo posso demorar para vender?", "O registro e a publicação de sua loja no Cazappi são gratuitos. A única cobrança é a comissão de 15% de cada pedido feito. Uma vez que sua loja, filiais e catálogo de produtos tenham sido configurados, você pode solicitar sua publicação. Depois de verificarmos se você atende a todos os requisitos, você pode começar a receber pedidos."],
    ["Como é processado o pedido?","Quando o cliente faz seu pedido é enviada uma notificação para sua loja com o número identificador do pedido."],
    ["Como é feita a entrega do produto?", "Você poderá escolher se você entrega ou se o cliente retira."],
    ["Como e quanto eu recebo pelas minhas vendas?", "A fatura dos nossos serviços será emitida na sexta-feira de cada semana. Todas as quartas-feiras você receberá o total de suas vendas na(s) conta(s) bancária(s) que especificou na configuração de sua loja. O valor total do depósito será igual ao total das vendas realizadas em sua(s) loja(s) através do Cazappi menos a nossa comissão."],
    ["Qual a área de atuação que a minha loja poderá atingir?", "Você poderá estabelecer a distância que você consegue atender em entrega."],
    ["Quais produtos posso vender?", "Todo e qualquer produto de alimento industrializado, bebida, artesanais, lanches, mercearia, etc.... "],
    ["Quais formas de pagamentos são aceitas no Cazappi?", "Pix, débito ou crédito."]
]

export default textsFields;